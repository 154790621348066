import { Component, OnInit } from '@angular/core';
import { PageType } from '../models/app-model';
import { PlatformLocation } from '@angular/common';
import { ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-cors',
  templateUrl: './cors.component.html',
  styleUrls: ['./cors.component.scss']
})
export class CorsComponent implements OnInit {

  pageType: PageType;
  domain: string;
  constructor(
    private route: ActivatedRoute,
    private platformLocation: PlatformLocation) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: {corsType: string}) => {
        this.domain = '//cors.konaqa.com';
        this.pageType = PageType.cors;
        if (data.corsType === 'subdomain') {          
          this.pageType = PageType.corsSubdomain;
          this.domain = '//cors.' + this.platformLocation.hostname.replace(/(.*?)\.(?=[^\/]*\..{2,5})/, '');
        }
      })
  }


}
