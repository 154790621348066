import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HomepageComponent } from './homepage/homepage.component';
import { BasePageComponent } from './base-page/base-page.component';
import { FormpostComponent } from './formpost/formpost.component';
import { AjaxComponent } from './ajax/ajax.component';
import { FetchComponent } from './fetch/fetch.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ModalComponent } from './modal/modal.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { IframeComponent } from './iframe/iframe.component';
import { CorsComponent } from './cors/cors.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AjaxService } from './services/ajax.service';
import { FetchService } from './services/fetch.service';
import { SafePipe } from './filters/safe.pipe';
import { HeadersComponent } from './headers/headers.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    PageHeaderComponent,
    HomepageComponent,
    BasePageComponent,
    FormpostComponent,
    AjaxComponent,
    FetchComponent,
    SuccessPageComponent,
    ModalComponent,
    SimplePageComponent,
    IframeComponent,
    CorsComponent,
    SafePipe,
    HeadersComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  providers: [AjaxService, FetchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
