import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FetchService {

  constructor(private http: HttpClient) {}

  makeGetRequest(url: string, headers?: any, useCustomImpl?: boolean) {
    const httpOptions: RequestInit = {
      method: 'GET',
      headers: headers,
      credentials: 'include'
    };
    if (useCustomImpl) {
      // @ts-ignore
      return window.customFetch(url, httpOptions);;
    }
    return fetch(url, httpOptions);
  }

  makePostRequest(url: string, data: any, headers?: any, withCredentials?: boolean, useCustomImpl?: boolean) {
    const httpOptions: RequestInit = {
      method: 'POST',
      headers: headers,
      body: data,
      credentials: 'include'
    };
    if (withCredentials) {
      httpOptions['credentials'] = 'include';
    }
    if (useCustomImpl) {
      // @ts-ignore
      return window.customFetch(url, httpOptions);
    }
    return fetch(url, httpOptions);
  }
}
