import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AjaxService } from '../services/ajax.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-ajax',
  templateUrl: './ajax.component.html',
  styleUrls: ['./ajax.component.scss']
})
export class AjaxComponent implements OnInit {

  @Input() url: string;
  @Input() form: FormGroup;
  @Input() type: string;
  @Input() cors: boolean = false;
  headers: HttpHeaders;
  headerValues: string = '';
  error: boolean = false;
  constructor(
    private ajaxService: AjaxService,
    private router: Router
    ) { }

  ngOnInit() {
    this.headerValues = JSON.stringify({
      'Content-Type': 'application/json'
    })
  }

  sendAjax() {
    this.error = false;
    this.headers = new HttpHeaders(JSON.parse(this.headerValues));
    if(this.type == 'post') {
      this.ajaxService.makePostRequest(this.url, this.form.value, this.headers)
        .subscribe((data: any) => {
          if (data.success ==  true) {
            this.router.navigate(['/success', {ipr: data.ipr}]);
          } else {
            this.error = true;
          }
        });

    } else {
      
      this.ajaxService.makeGetRequest(this.url, this.headers)
        .subscribe((data: any) => {
          this.router.navigate(['/success', {ipr: data.ipr}]);
        });
    }

  }
}
