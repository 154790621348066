import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { BasePageComponent } from './base-page/base-page.component';
import { SuccessPageComponent } from './success-page/success-page.component';
import { ModalComponent } from './modal/modal.component';
import { IframeComponent } from './iframe/iframe.component';
import { SimplePageComponent } from './simple-page/simple-page.component';
import { CorsComponent } from './cors/cors.component';


const routes: Routes = [
  { path: 'success', component: SuccessPageComponent },
  { path: 'modal', component: ModalComponent },
  { path: 'modal/:formType', component: ModalComponent },
  { path: 'iframe', component: IframeComponent },
  { path: 'iframe/:formType', component: IframeComponent },
  { path: 'page', component: SimplePageComponent },
  { path: 'page/:formType', component: SimplePageComponent },
  { path: 'cors', component: CorsComponent, data: { corsType: "domain" } },
  { path: 'cors/:formType', component: CorsComponent, data: { corsType: "domain" } },
  { path: 'cors-subdomain', component: CorsComponent, data: { corsType: "subdomain" } },
  { path: 'cors-subdomain/:formType', component: CorsComponent, data: { corsType: "subdomain" } },
  { path: '', component: HomepageComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
