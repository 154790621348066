import { Component, OnInit } from '@angular/core';
import { PageType } from '../models/app-model';

@Component({
  selector: 'app-simple-page',
  templateUrl: './simple-page.component.html',
  styleUrls: ['./simple-page.component.scss']
})
export class SimplePageComponent implements OnInit {

  pageType: PageType = PageType.page;
  constructor() { }

  ngOnInit() {
  }

}
