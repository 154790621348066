import { Component, OnInit, Sanitizer } from '@angular/core';
import { PageType } from '../models/app-model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

  pageType: PageType = PageType.iframe;
  url: string;
  iframeUrl: string;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.iframeUrl = '//iframe.konaqa.com';
    this.url = '//iframe.konaqa.com';
  }

  refreshUrl() {
    this.iframeUrl = this.url;
  }

}
