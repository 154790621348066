import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PageType, FormType } from '../models/app-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'

@Component({
  selector: 'base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit {


  @Input() pageType: PageType;
  @Input() formType: FormType;
  @Input() domain: string = '';
  @Input() cors: boolean = false;
  FormType = FormType;
  loaded = false;
  form: FormGroup;
  url: string;
  urlPath: string = '/login';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.formType = FormType[params.get('formType')];

      if ((params.get('formType') && !this.formType)) {
        this.router.navigate([this.pageType]);
      }
      this.url = this.domain + this.urlPath;
    })

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.loaded = true;
  }

  pathUpdated() {
    this.url = this.domain + this.urlPath;
  }

  

  



}
