import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  inputError = false;
  headerValue: string;
  
  @Input() get header() {
    return this.headerValue;
  }

  @Output() headerChange = new EventEmitter<string>();
  set header(val) {
    this.headerValue = val;
    this.headerChange.emit(this.headerValue); 
  }
  
  ngOnInit() {
  }

  changeHeaders(values) {
    try {
      JSON.parse(values);
      this.headerValue = values;
      this.headerChange.emit(this.headerValue); 
      this.inputError = false;
    } catch (e) {
      this.inputError = true;
    }
  }
}
