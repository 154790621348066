import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FetchService } from '../services/fetch.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-fetch',
  templateUrl: './fetch.component.html',
  styleUrls: ['./fetch.component.scss']
})
export class FetchComponent implements OnInit {

  @Input() url: string;
  @Input() form: FormGroup;
  @Input() type: string;
  @Input() cors: boolean = false;
  headers: string;
  error: boolean = false;
  reqObject: boolean = false;
  customImpl: boolean = false;
  constructor(
    private router: Router,
    private fetchService: FetchService
  ) { }

  ngOnInit() {
    this.headers = JSON.stringify({
      'Content-Type': 'application/json'
    });
  }

  sendFetch() {
    const headerObj = JSON.parse(this.headers);
    let resource: any = this.url;
    this.error = false;
    if (this.reqObject) {
      resource = new Request(this.url);
    }
    if (this.type === 'post') {
      this.fetchService.makePostRequest(resource, JSON.stringify(this.form.value), headerObj, null, this.customImpl)
        .then(response => {
          if (response.status === 428 && this.customImpl) {
            return response.clone().json().then((res) => {
              // @ts-ignore
              if (window.ak_chlge) {
                // @ts-ignore
                window.ak_chlge.showChallenge(res, this.url);
              }
              return Promise.reject(res);
            });
          } else {
            return response.json();
          }
        })
        .then(data => {
          if (data.success === true) {
            this.router.navigate(['/success', {ipr: data.ipr}]);
          } else {
            this.error = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.fetchService.makeGetRequest(resource, headerObj, this.customImpl)
        .then(response => {
          if (response.status === 428 && this.customImpl) {
            return response.clone().json().then((res) => {
              // @ts-ignore
              if (window.ak_chlge) {
                // @ts-ignore
                window.ak_chlge.showChallenge(res, this.url);
              }
              return Promise.reject(res);
            });
          } else {
            return response.json();
          }
        })
        .then(data => {
          this.router.navigate(['/success', {ipr: data.ipr}]);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

}
