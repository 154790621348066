import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'challenge-test';

  constructor() {
    // Holding the reference to the global fetch to use it after its overridden by sec-cpt.js
    if (typeof fetch === 'function') {
      // @ts-ignore
      window.customFetch = fetch;
    }
  }
}
