import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class AjaxService {

  constructor(private http: HttpClient) { }

  makeGetRequest(url: string, headers?: HttpHeaders) {
    const httpOptions = {
      headers: headers,
      withCredentials : true
    };
    return this.http.get(url, httpOptions);
  }

  makePostRequest(url: string, data: any, headers?: HttpHeaders) {
    const httpOptions = {
      headers: headers,
      withCredentials : true
    };
    return this.http.post(url, data, httpOptions);
  }

}