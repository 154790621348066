export enum PageType {
  page = 'page',
  modal = 'modal',
  iframe = 'iframe',
  cors = 'cors',
  corsSubdomain = 'cors-subdomain'
}

export enum FormType {
  formpost = 'formpost',
  ajax = 'ajax',
  fetch = 'fetch',
  formget = 'formget',
  ajaxget = 'ajaxget',
  fetchget = 'fetchget'
}