import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-formpost',
  templateUrl: './formpost.component.html',
  styleUrls: ['./formpost.component.scss']
})
export class FormpostComponent implements OnInit {

  @Input() url: string;
  @Input() form: FormGroup;
  @Input() type: string;
  @Input() cors: boolean = true;

  constructor() { }

  ngOnInit() {

  }


}
